<template>
  <div class="px-3">
    <div class="font-weight-bold blue--text text--darken-2" >{{ lookupItemTemplateReg(itemModified.ftemplateRegBean).description }}</div>
    <v-alert
      dense
      class="mt-1 subtitle-2"
      outlined
      color="warning"
      icon="mdi-alert-circle-outline"
      border="left"
    >
      Untuk Usulan KP IV/c ke atas JP Madya, JPT Utama dan JF Utama diusulkan
      melalui aplikasi SIAPP Kementerian Sekretariat Negara.
    </v-alert>
    <v-row class="mt-4" v-if="true">
      <v-col
        cols="12"
        md="4"
        sm="3"
        class="py-0 my-0"
        v-if="
          lookupItemTemplateReg(itemModified.ftemplateRegBean).showPeriodeBulan
        "
      >
        <div class="d-flex flex-column px-2">
          <span class="mb-2">Periode Bulan</span>
          <v-autocomplete
            v-model="itemModified.periodeMonth"
            outlined
            :items="itemsMonth"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            color="blue-grey lighten-2"
            persistent-hint
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="3"
        sm="2"
        class="py-0 my-0"
        v-if="
          lookupItemTemplateReg(itemModified.ftemplateRegBean).showPeriodeTahun
        "
      >
        <div class="d-flex flex-column px-2">
          <span class="mb-2">Periode Tahun</span>
          <v-autocomplete
            v-model="itemModified.periodeYear"
            outlined
            :items="itemsYear"
            auto-select-first
            dense
            color="blue-grey lighten-2"
            persistent-hint
          ></v-autocomplete>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="true">
      <v-col
        v-if="
          lookupItemTemplateReg(itemModified.ftemplateRegBean).showJenisJabatan
        "
        cols="12"
        md="4"
        sm="6"
        class="py-0 my-0"
      >
        <div class="d-flex flex-column px-2">
          <span class="mb-2">Jenis Jabatan</span>
          <v-autocomplete
            v-model="itemModified.fjenisJabatanBean"
            outlined
            :items="itemsFJenisJabatan"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            color="blue-grey lighten-2"
            persistent-hint
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="8"
        sm="6"
        class="py-0 my-0"
        v-if="
          lookupItemTemplateReg(itemModified.ftemplateRegBean)
            .showJenisKenaikanPangkat
        "
      >
        <div class="d-flex flex-column px-2">
          <span class="mb-2">Jenis Kenaikan Pangkat</span>
          <v-autocomplete
            v-model="itemModified.fjenisKenaikanPangkatBean"
            outlined
            :items="itemsFJenisKenaikanPangkat"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            color="blue-grey lighten-2"
            persistent-hint
          ></v-autocomplete>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="4"
        sm="6"
        class="py-0 my-0"
        v-if="
          lookupItemTemplateReg(itemModified.ftemplateRegBean).showJenisProsedur
        "
      >
        <div class="d-flex flex-column px-2">
          <span class="mb-2">Jenis Prosedur</span>
          <v-autocomplete
            v-model="itemModified.fjenisProsedurBean"
            outlined
            :items="itemsFJenisProsedur"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            color="blue-grey lighten-2"
            persistent-hint
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="4"
        sm="6"
        class="py-0 my-0"
        v-if="
          lookupItemTemplateReg(itemModified.ftemplateRegBean).showJenisKartu
        "
      >
        <div class="d-flex flex-column px-2">
          <span class="mb-2">Jenis Kartu</span>
          <v-autocomplete
            v-model="itemModified.fjenisKartuBean"
            outlined
            :items="itemsFJenisKartu"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            color="blue-grey lighten-2"
            persistent-hint
          ></v-autocomplete>
        </div>
      </v-col>

      <v-col cols="12" md="4" sm="6" class="py-0 my-0" v-if="false">
        <div class="d-flex flex-column px-2">
          <span class="mb-2">Dinas/OPD</span>
          <v-autocomplete
            v-model="itemModified.fdivisionBean"
            outlined
            :items="itemsFDivision"
            item-value="id"
            item-text="description"
            auto-select-first
            dense
            color="blue-grey lighten-2"
            persistent-hint
          ></v-autocomplete>
        </div>
      </v-col>
    </v-row>
    <v-row> </v-row>

  </div>
</template>

<script>
import { EPeriodeMonths } from "@/models/e-periode-month";
export default {
  name: "FtRegistrasiLayananDesc1",
  props: {
  },
  data() {
    return {
      tab: "tab1",
      radio: null,

      items: {
        id: 1,
        periode: "Oktober",
        tahun: 2021,
        jenisKenaikanPangkat: 1,
      },
      itemsYear: [2023, 2024, 2025, 2026, 2027],
      itemsMonth: EPeriodeMonths,
      rulesLenght: [
        (v) => !!v || " Tidak Boleh Kosong",
        (v) => v.length <= 255 || "Maksimal 255 Karakter",
        (v) => v.length > 1 || "Harus lebih besar dari 1 karakter",
      ],
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],
    };
  },
  computed: {
    itemModified: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemModified;
      },
      set(val) {
        this.$store.dispatch("FtRegistrasiModule/updateItemModified", val);
      },
    },
    itemsFDivision: {
      get() {
        return this.$store.state.FTemplateRegModule.itemsFDivision;
      },
      set(value) {
        this.$store.dispatch("FTemplateRegModule/updateItemsFDivision", value);
      },
    },

    itemsFJenisDokumen: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisDokumen;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisDokumen",
          value
        );
      },
    },
    itemsFJenisJabatan: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisJabatan;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisJabatan",
          value
        );
      },
    },
    itemsFJenisProsedur: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisProsedur;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisProsedur",
          value
        );
      },
    },
    itemsFJenisKenaikanPangkat: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisKenaikanPangkat;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisKenaikanPangkat",
          value
        );
      },
    },
    itemsFJenisKartu: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFJenisKartu;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFJenisKartu",
          value
        );
      },
    },

    itemsFTemplateReg: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFTemplateReg;
      },
      set(value) {
        this.$store.dispatch(
          "FtRegistrasiModule/updateItemsFTemplateReg",
          value
        );
      },
    },
  },

  methods: {
    lookupItemTemplateReg(fTemplateRegBean) {
      const str = this.itemsFTemplateReg.filter(
        (x) => x.id === fTemplateRegBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },

  },
};
</script>