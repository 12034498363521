var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4"},[_c('v-card',[_c('v-card-text',{staticClass:"d-flex"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.fetchFtRegistrasiFile(_vm.itemModified.id)}}},[_vm._v("Reload Data")])],1),_c('v-card-text',[_c('v-container',{staticClass:"pa-4"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.itemsFtRegistrasiFile,"page":_vm.currentPage,"items-per-page":_vm.pageSize,"hide-default-footer":""},on:{"update:page":function($event){_vm.currentPage=$event},"page-count":function($event){_vm.totalTablePages = _vm.totalPaginationPages}},scopedSlots:_vm._u([{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.description)+" ")]),(item.mandatory)?_c('span',[_c('v-icon',{attrs:{"color":"red","x-small":""}},[_vm._v("mdi-star-circle")])],1):_vm._e()]}},{key:"item.fileName",fn:function(ref){
var item = ref.item;
return [(item.fileName !== '')?_c('div',{staticClass:"caption"},[_c('a',{attrs:{"href":("" + (_vm.downloadFile(item))),"target":"_blank"}},[_c('v-icon',{attrs:{"color":"green","small":""}},[_vm._v("mdi-download")]),_vm._v(" "+_vm._s(item.fileName)+" ")],1)]):_vm._e()]}},{key:"item.modifiedBy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(item.modifiedBy)+" ")]),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.formatCreatedDate(item.created))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.showDialogUpload(item)}}},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v(" mdi-upload ")])],1),(false)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteDialogShow(item)}}},[_c('v-icon',{attrs:{"small":"","color":"red accent-4"}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}}],null,true)})],1)],1),(false)?_c('v-card-actions',[_c('v-container',{staticClass:"text-center"},[_c('v-btn',{attrs:{"color":"primary","block":""},on:{"click":_vm.showDialogUpload}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-upload")]),_vm._v(" Upload ")],1)],1)],1):_vm._e()],1),_c('UploadDocumentSimpleDialog',{ref:"refUploadDialog",on:{"eventUploadSuccess":_vm.completeUploadSuccess}}),_c('delete-confirm-dialog',{ref:"refDeleteConfirmDialog",on:{"eventFromDeleteConfirmDialog1":_vm.deleteItemConfirmedSingleSelect,"eventFromDeleteConfirmDialog2":_vm.deleteItemConfirmedMultiSelect}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }