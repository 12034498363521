const EPeriodeMonth = Object.freeze({
  Februari: 2,
  April: 4,
  Juni: 6,
  Agustus: 8,
  Oktober: 10,
  Desember: 12,
});
const EPeriodeMonths = Object.freeze([
  {
    id: EPeriodeMonth.Februari,
    description: "Februari",
  },{
    id: EPeriodeMonth.April,
    description: "April",
  },{
    id: EPeriodeMonth.Juni,
    description: "Juni",
  },{
    id: EPeriodeMonth.Agustus,
    description: "Agustus",
  },{
    id: EPeriodeMonth.Oktober,
    description: "Oktober",
  },{
    id: EPeriodeMonth.Desember,
    description: "Desember",
  },

]);
export { EPeriodeMonth as default, EPeriodeMonths };
