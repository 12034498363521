import axios from 'axios';
import authHeader from "@/services/auth-header";
import ConstApiUrls from "@/services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class ApiSiasnService {
    getApiUrl() {
        return API_URL
    }

    getSiasnDataUtama(nip){
        return axios.get(API_URL + `public/getSiasnDataUtama/${nip}`, { headers: authHeader() });
    }
}
export default new ApiSiasnService()