var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-3"},[_c('div',{staticClass:"font-weight-bold blue--text text--darken-2"},[_vm._v(_vm._s(_vm.lookupItemTemplateReg(_vm.itemModified.ftemplateRegBean).description))]),_c('v-alert',{staticClass:"mt-1 subtitle-2",attrs:{"dense":"","outlined":"","color":"warning","icon":"mdi-alert-circle-outline","border":"left"}},[_vm._v(" Untuk Usulan KP IV/c ke atas JP Madya, JPT Utama dan JF Utama diusulkan melalui aplikasi SIAPP Kementerian Sekretariat Negara. ")]),(true)?_c('v-row',{staticClass:"mt-4"},[(
        _vm.lookupItemTemplateReg(_vm.itemModified.ftemplateRegBean).showPeriodeBulan
      )?_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"4","sm":"3"}},[_c('div',{staticClass:"d-flex flex-column px-2"},[_c('span',{staticClass:"mb-2"},[_vm._v("Periode Bulan")]),_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.itemsMonth,"item-value":"id","item-text":"description","auto-select-first":"","dense":"","color":"blue-grey lighten-2","persistent-hint":""},model:{value:(_vm.itemModified.periodeMonth),callback:function ($$v) {_vm.$set(_vm.itemModified, "periodeMonth", $$v)},expression:"itemModified.periodeMonth"}})],1)]):_vm._e(),(
        _vm.lookupItemTemplateReg(_vm.itemModified.ftemplateRegBean).showPeriodeTahun
      )?_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"3","sm":"2"}},[_c('div',{staticClass:"d-flex flex-column px-2"},[_c('span',{staticClass:"mb-2"},[_vm._v("Periode Tahun")]),_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.itemsYear,"auto-select-first":"","dense":"","color":"blue-grey lighten-2","persistent-hint":""},model:{value:(_vm.itemModified.periodeYear),callback:function ($$v) {_vm.$set(_vm.itemModified, "periodeYear", $$v)},expression:"itemModified.periodeYear"}})],1)]):_vm._e()],1):_vm._e(),(true)?_c('v-row',[(
        _vm.lookupItemTemplateReg(_vm.itemModified.ftemplateRegBean).showJenisJabatan
      )?_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('div',{staticClass:"d-flex flex-column px-2"},[_c('span',{staticClass:"mb-2"},[_vm._v("Jenis Jabatan")]),_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.itemsFJenisJabatan,"item-value":"id","item-text":"description","auto-select-first":"","dense":"","color":"blue-grey lighten-2","persistent-hint":""},model:{value:(_vm.itemModified.fjenisJabatanBean),callback:function ($$v) {_vm.$set(_vm.itemModified, "fjenisJabatanBean", $$v)},expression:"itemModified.fjenisJabatanBean"}})],1)]):_vm._e(),(
        _vm.lookupItemTemplateReg(_vm.itemModified.ftemplateRegBean)
          .showJenisKenaikanPangkat
      )?_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"8","sm":"6"}},[_c('div',{staticClass:"d-flex flex-column px-2"},[_c('span',{staticClass:"mb-2"},[_vm._v("Jenis Kenaikan Pangkat")]),_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.itemsFJenisKenaikanPangkat,"item-value":"id","item-text":"description","auto-select-first":"","dense":"","color":"blue-grey lighten-2","persistent-hint":""},model:{value:(_vm.itemModified.fjenisKenaikanPangkatBean),callback:function ($$v) {_vm.$set(_vm.itemModified, "fjenisKenaikanPangkatBean", $$v)},expression:"itemModified.fjenisKenaikanPangkatBean"}})],1)]):_vm._e()],1):_vm._e(),_c('v-row',[(
        _vm.lookupItemTemplateReg(_vm.itemModified.ftemplateRegBean).showJenisProsedur
      )?_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('div',{staticClass:"d-flex flex-column px-2"},[_c('span',{staticClass:"mb-2"},[_vm._v("Jenis Prosedur")]),_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.itemsFJenisProsedur,"item-value":"id","item-text":"description","auto-select-first":"","dense":"","color":"blue-grey lighten-2","persistent-hint":""},model:{value:(_vm.itemModified.fjenisProsedurBean),callback:function ($$v) {_vm.$set(_vm.itemModified, "fjenisProsedurBean", $$v)},expression:"itemModified.fjenisProsedurBean"}})],1)]):_vm._e(),(
        _vm.lookupItemTemplateReg(_vm.itemModified.ftemplateRegBean).showJenisKartu
      )?_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('div',{staticClass:"d-flex flex-column px-2"},[_c('span',{staticClass:"mb-2"},[_vm._v("Jenis Kartu")]),_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.itemsFJenisKartu,"item-value":"id","item-text":"description","auto-select-first":"","dense":"","color":"blue-grey lighten-2","persistent-hint":""},model:{value:(_vm.itemModified.fjenisKartuBean),callback:function ($$v) {_vm.$set(_vm.itemModified, "fjenisKartuBean", $$v)},expression:"itemModified.fjenisKartuBean"}})],1)]):_vm._e(),(false)?_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"12","md":"4","sm":"6"}},[_c('div',{staticClass:"d-flex flex-column px-2"},[_c('span',{staticClass:"mb-2"},[_vm._v("Dinas/OPD")]),_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.itemsFDivision,"item-value":"id","item-text":"description","auto-select-first":"","dense":"","color":"blue-grey lighten-2","persistent-hint":""},model:{value:(_vm.itemModified.fdivisionBean),callback:function ($$v) {_vm.$set(_vm.itemModified, "fdivisionBean", $$v)},expression:"itemModified.fdivisionBean"}})],1)]):_vm._e()],1),_c('v-row')],1)}
var staticRenderFns = []

export { render, staticRenderFns }