export default class FtRegistrasiFile {
  constructor(
    id,
    fileName,
    description,
    fileType,
    ftRegistrasiBean,
    ftemplateRegDokumenBean,
    fjenisDokumenBean,
    tanggalFile = new Date(),

    flag,
    docType,
    statusAlasan,
    mandatory,
    statusValid,

    created,
    modifiedBy
  ) {
    this.id = id;
    this.fileName = fileName;
    this.description = description;
    this.fileType = fileType;
    this.ftRegistrasiBean = ftRegistrasiBean;
    this.ftemplateRegDokumenBean = ftemplateRegDokumenBean;
    this.fjenisDokumenBean = fjenisDokumenBean;
    this.tanggalFile = tanggalFile;

    this.flag = flag;
    this.docType = docType;
    this.statusAlasan = statusAlasan;
    this.mandatory = mandatory;
    this.statusValid = statusValid;

    this.created = created;
    this.modifiedBy = modifiedBy;
  }
}
