<template>
  <div class="px-3">
    <v-alert
      dense
      class="mt-4 subtitle-2"
      outlined
      color="warning"
      icon="mdi-alert-circle-outline"
      border="left"
    >
      Pastikan Anda menyimpan data ketika melakukan perubahan dengan menekan
      tombol simpan dibagian kanan bawah sebelum melanjutkan proses pengusulan.
    </v-alert>
    <v-row class="py-4" v-if="isAllDescValid && isAllDocumentValid">
      <v-col cols="12" align="center">
        <p class="headline font-weight-regular green--text">
          Berkas Peryaratan Sudah Lengkap
        </p>
        <p class="subtitle-1 font-weight-bold green--text">
          Silahkan lanjutkan proses pengusulan dengan menekan tombol Submit
        </p>
      </v-col>
    </v-row>
    <v-card v-if="! isAllDescValid" class="mt-8 mb-8">
      <v-card-title
        class="white--text font-weight-black"
        style="background-color: #3bc26a"
        >DATA USULAN</v-card-title
      >
      <v-divider></v-divider>
      <v-row class="mt-0 mx-2">
        <v-col
          class="px-0 mx-0 my-0 pt-0 pb-0"
          cols="12"
          md="12"
          sm="6"
          v-if="isJenisProsedurFalse"
        >
          <v-flex class="d-flex flex-row py-3">
            <div class="my-0 py-0">
              Jenis Prosedur
              <span><v-icon color="red" x-small>mdi-star-circle</v-icon></span>
            </div>
            <v-spacer></v-spacer>
            <v-chip dark color="red lighten-2" small>Belum Terisi</v-chip>
          </v-flex>
          <v-divider></v-divider>
        </v-col>
        <v-col
          class="px-0 mx-0 my-0 pt-0 pb-0"
          cols="12"
          md="12"
          sm="6"
          v-if="isJenisJabatanFalse"
        >
          <v-flex class="d-flex flex-row py-3">
            <div class="my-0 py-0">
              Jenis Jabatan
              <span><v-icon color="red" x-small>mdi-star-circle</v-icon></span>
            </div>
            <v-spacer></v-spacer>
            <v-chip dark color="red lighten-2" small>Belum Terisi</v-chip>
          </v-flex>
          <v-divider></v-divider>
        </v-col>
        <v-col
          class="px-0 mx-0 my-0 pt-0 pb-0"
          cols="12"
          md="12"
          sm="6"
          v-if="isJenisJabatanFalse"
        >
          <v-flex class="d-flex flex-row py-3">
            <div class="my-0 py-0">
              Jenis Kenaikan Jabatan
              <span><v-icon color="red" x-small>mdi-star-circle</v-icon></span>
            </div>
            <v-spacer></v-spacer>
            <v-chip dark color="red lighten-2" small>Belum Terisi</v-chip>
          </v-flex>
          <v-divider></v-divider>
        </v-col>
        <v-col
          class="px-0 mx-0 my-0 pt-0 pb-0"
          cols="12"
          md="12"
          sm="6"
          v-if="isJenisKenaikanPangkatFalse"
        >
          <v-flex class="d-flex flex-row py-3">
            <div class="my-0 py-0">
              Jenis Kenaikan Pangkat
              <span><v-icon color="red" x-small>mdi-star-circle</v-icon></span>
            </div>
            <v-spacer></v-spacer>
            <v-chip dark color="red lighten-2" small>Belum Terisi</v-chip>
          </v-flex>
          <v-divider></v-divider>
        </v-col>
        <v-col
          class="px-0 mx-0 my-0 pt-0 pb-0"
          cols="12"
          md="12"
          sm="6"
          v-if="isPeriodeBulanFalse"
        >
          <v-flex class="d-flex flex-row py-3">
            <div class="my-0 py-0">
              Periode Bulan
              <span><v-icon color="red" x-small>mdi-star-circle</v-icon></span>
            </div>
            <v-spacer></v-spacer>
            <v-chip dark color="red lighten-2" small>Belum Terisi</v-chip>
          </v-flex>
          <v-divider></v-divider>
        </v-col>
        <v-col
          class="px-0 mx-0 my-0 pt-0 pb-0"
          cols="12"
          md="12"
          sm="6"
          v-if="isPeriodeTahunFalse"
        >
          <v-flex class="d-flex flex-row py-3">
            <div class="my-0 py-0">
              Periode Tahun
              <span><v-icon color="red" x-small>mdi-star-circle</v-icon></span>
            </div>
            <v-spacer></v-spacer>
            <v-chip dark color="red lighten-2" small>Belum Terisi</v-chip>
          </v-flex>
          <v-divider></v-divider>
        </v-col>
        <v-col
          class="px-0 mx-0 my-0 pt-0 pb-0"
          cols="12"
          md="12"
          sm="6"
          v-if="isJenisKartuFalse"
        >
          <v-flex class="d-flex flex-row py-3">
            <div class="my-0 py-0">
              Jenis Kartu
              <span><v-icon color="red" x-small>mdi-star-circle</v-icon></span>
            </div>
            <v-spacer></v-spacer>
            <v-chip dark color="red lighten-2" small>Belum Terisi</v-chip>
          </v-flex>
          <v-divider></v-divider>
        </v-col>
        <v-col
          class="px-0 mx-0 my-0 pt-0 pb-0"
          cols="12"
          md="12"
          sm="6"
          v-if="isNipEmptyFalse"
        >
          <v-flex class="d-flex flex-row py-3">
            <div class="my-0 py-0">
              NIP (Nomor Induk Pegawai)
              <span><v-icon color="red" x-small>mdi-star-circle</v-icon></span>
            </div>
            <v-spacer></v-spacer>
            <v-chip dark color="red lighten-2" small>Belum Terisi</v-chip>
          </v-flex>
          <v-divider></v-divider>
        </v-col>
        <v-col
          class="px-0 mx-0 my-0 pt-0 pb-0"
          cols="12"
          md="12"
          sm="6"
          v-if="isNipValidFalse"
        >
          <v-flex class="d-flex flex-row py-3">
            <div class="my-0 py-0">
              NIP (Nomor Induk Pegawai) Tidak Valid
              <span><v-icon color="red" x-small>mdi-star-circle</v-icon></span>
            </div>
            <v-spacer></v-spacer>
            <v-chip dark color="red lighten-2" small>Tidak Valid</v-chip>
          </v-flex>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-if="! isAllDocumentValid" class="mt-8 mb-8">
      <v-card-title
        class="white--text font-weight-black"
        style="background-color: #477ab5"
        >DATA DOKUMEN</v-card-title
      >
      <v-divider></v-divider>
      <v-row class="mt-0 mx-2">
        <v-col
          v-for="(itemFile, index) in itemsFtRegistrasiFile.filter( item => item.mandatory === true && item.fileName === '' )"
          :key="index"
          class="px-0 mx-0 my-0 pt-0 pb-0"
          cols="12"
          md="12"
          sm="6"
        >
          <v-flex class="d-flex flex-row py-3">
            <div class="my-0 py-0">
              {{ itemFile.description }}
              <span><v-icon color="red" x-small>mdi-star-circle</v-icon></span>
            </div>
            <v-spacer></v-spacer>
            <v-chip dark color="red lighten-2" small>Belum Terupload</v-chip>
          </v-flex>
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-card>

    <div class="d-flex">
      <v-btn color="secondary" @click="setStep3">Sebelumnya</v-btn>
      <v-spacer></v-spacer>
      <v-btn
          color="primary"
          :disabled="! (isAllDescValid  && isAllDocumentValid)"
          @click="submit"
      >Submit</v-btn
      >
    </div>
    <SubmitConfirmDialog
        ref="refSubmitConfirmDialog"
        @eventFromSubmitConfirmDialog="confirmSubmit"
    ></SubmitConfirmDialog>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import SubmitConfirmDialog from "@/components/utils/SubmitConfirmDialog.vue";
export default {
  name: "FtRegistrasiLayananSummary",
  components: {
    SubmitConfirmDialog
  },
  props: {
    // itemModified: Object,
  },
  data() {
    return {
      snackBarMesage: "",
      snackbar: false,
      dataSiasn: "",
      transparent: "rgba(255, 255, 255, 0)",
      showSiasnData: false,
      itemPns: {},
      date: format(parseISO(new Date().toISOString()), "dd-MMM-yyyy"),
      menuDate1: false,
      itemsTahun: [
        { id: 1, description: 2018 },
        { id: 2, description: 2019 },
        { id: 3, description: 2020 },
        { id: 4, description: 2021 },
        { id: 5, description: 2022 },
        { id: 6, description: 2023 },
        { id: 7, description: 2024 },
      ],
      rulesLenght: [
        (v) => !!v || " Tidak Boleh Kosong",
        (v) => v.length <= 255 || "Maksimal 255 Karakter",
        (v) => v.length > 1 || "Harus lebih besar dari 1 karakter",
      ],
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],
    };
  },
  computed: {

    itemModified: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemModified;
      },
      set(val) {
        this.$store.dispatch("FtRegistrasiModule/updateItemModified", val);
      },
    },

    itemsFTemplateReg: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFTemplateReg;
      },
      set(value) {
        this.$store.dispatch(
            "FtRegistrasiModule/updateItemsFTemplateReg",
            value
        );
      },
    },

    itemsFtRegistrasiFile: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFtRegistrasiFile;
      },
      set(value) {
        this.$store.dispatch(
            "FtRegistrasiModule/updateItemsFtRegistrasiFile",
            value
        )
      }
    },

    isAllDescValid(){
      return ! (this.isNipEmptyFalse ||this.isNipValidFalse || this.isPeriodeTahunFalse || this.isPeriodeBulanFalse || this.isJenisProsedurFalse || this.isJenisKenaikanPangkatFalse || this.isJenisJabatanFalse || this.isJenisKartuFalse)
    },
    // isAllDescValid(){
    //   console.log('isAllDescValid', this.isNipEmptyFalse, this.isNipValidFalse, this.isPeriodeTahunFalse, this.isPeriodeBulanFalse, this.isJenisProsedurFalse, this.isJenisKenaikanPangkatFalse, this.isJenisJabatanFalse, this.isJenisKartuFalse)
    //   return false
    // },

    isAllDocumentValid(){
      return this.itemsFtRegistrasiFile.filter( item => item.mandatory === true && item.fileName === '' ).length ===0
    },
    isNipValidFalse(){
      return this.itemModified.nip !== '' && this.itemModified.description === ''
    },
    isNipEmptyFalse(){
      return this.itemModified.nip === ''
    },

    isPeriodeTahunFalse(){
      return this.lookupItemTemplateReg(this.itemModified.ftemplateRegBean).showPeriodeTahun === true &&
          this.itemModified.periodeYear === 0;
    },
    isPeriodeBulanFalse(){
      return this.lookupItemTemplateReg(this.itemModified.ftemplateRegBean).showPeriodeBulan === true &&
          this.itemModified.periodeMonth === 0;
    },
    isJenisProsedurFalse(){
      return this.lookupItemTemplateReg(this.itemModified.ftemplateRegBean).showJenisProsedur === true &&
          this.itemModified.fjenisProsedurBean === 0;
    },
    isJenisKenaikanPangkatFalse(){
      return this.lookupItemTemplateReg(this.itemModified.ftemplateRegBean).showJenisKenaikanPangkat === true &&
          this.itemModified.fjenisKenaikanPangkatBean === 0;
    },
    isJenisJabatanFalse(){
      return this.lookupItemTemplateReg(this.itemModified.ftemplateRegBean).showJenisJabatan === true &&
          this.itemModified.fjenisJabatanBean === 0;
    },
    isJenisKartuFalse(){
      return this.lookupItemTemplateReg(this.itemModified.ftemplateRegBean).showJenisKartu === true &&
          this.itemModified.fjenisKartuBean === 0;
    },

  },
  watch: {

  },
  methods: {
    submit() {
      this.$refs.refSubmitConfirmDialog.showDialog(
          "Apakah ada yakin untuk Melakukan Submit Berkas?"
      );
    },
    setStep3() {
      this.$emit("setStep3", 3);
    },
    confirmSubmit(){
      this.$emit("submit");
    },
    lookupItemTemplateReg(fTemplateRegBean) {
      const str = this.itemsFTemplateReg.filter(
        (x) => x.id === fTemplateRegBean
      );
      if (str.length > 0) {
        return str[0];
      } else {
        return "-";
      }
    },
    computedDateFormattedDatefns(value) {
      return value ? format(parseISO(value), "dd MMM yyyy") : "";
    },
  },
};
</script>

<style scoped>
</style>