<template>
  <div class="px-3">
    <v-alert
      dense
      class="mt-4 subtitle-2"
      outlined
      color="warning"
      icon="mdi-alert-circle-outline"
      border="left"
    >
      Pastikan Anda menyimpan data ketika melakukan perubahan dengan menekan
      tombol simpan dibagian kanan bawah sebelum melanjutkan proses pengusulan.
    </v-alert>
    <v-row class="mt-4 mr-4" justify="center" align="center" v-if="false">
      <v-col cols="12" md="12">
        <v-checkbox
          hide-details
          dense
          v-model="itemPns.statusPenyederhanaanBirokrasi"
        >
          <template v-slot:label>
            <span class="subtitle-2">Status Penyederhaan Birokrasi</span>
          </template>
        </v-checkbox>
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="12" md="4" sm="6">
        <v-container class="pa-2 text-center">
          <v-row class="fill-height" align="center" justify="center">
            <v-hover v-slot="{ hover }">
              <v-card
                class="align-self-center"
                :elevation="hover ? 10 : 1"
                :class="[
                  { 'on-hover': hover },
                  hover ? 'card-hover-opacity' : 'card-not-hover-opacity',
                ]"
              >
                <v-img
                  :lazy-src="lookupImageUrlLazy(itemModified)"
                  :src="lookupImageUrl(itemModified)"
                  height="240px"
                  width="240px"
                  contain
                >
                  <v-card-title class="text-h6 white--text fill-height">
                    <v-row
                      class="fill-height flex-column"
                      justify="space-between"
                    >
                      <v-spacer />
                      <div class="align-self-center">
                        <v-btn
                          :class="{ 'show-btns': hover }"
                          :color="transparent"
                          icon
                          large
                          dark
                          outlined
                          @click="showDialogUpload"
                        >
                          <v-icon
                            :class="{ 'show-btns': hover }"
                            :color="transparent"
                            large
                          >
                            mdi-upload
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-row>
                  </v-card-title>
                </v-img>
              </v-card>
            </v-hover>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="12" md="8" sm="6">
        <v-row>
          <v-col cols="12" md="12" class="py-0 my-0">
            <span class="d-flex flex-column px-2">
              <span class="mb-2">NIP*</span>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="itemModified.nip"
                    :rules="rulesNotEmtpy"
                    outlined
                    dense
                    persistent-hint
                    hint="Masukkan 18 digit NIP Tanpa Spasi"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <span>
                    <v-btn
                      dark
                      style="background-color: #1d267d"
                      @click="cariPegawaiWithNip(itemModified.nip)"
                      >Cari Pegawai</v-btn
                    >
                  </span>
                </v-col>
              </v-row>
            </span>
          </v-col>
        </v-row>
        <v-row class="px-2" v-if="showSiasnData === true">
          <v-col cols="12" md="8" sm="6">
            <v-text-field
              :value="dataSiasn.nama === '' ? '-' : dataSiasn.nama"
              label="Nama Lengkap*"
              :rules="rulesNotEmtpy"
              outlined
              readonly
              dense
              hide-details
              >{{}}</v-text-field
            >
          </v-col>
        </v-row>
        <v-row class="px-2" v-if="showSiasnData === true">
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              :value="dataSiasn.gelarDepan === '' ? '-' : dataSiasn.gelarDepan"
              label="Gelar Depan"
              outlined
              readonly
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="4">
            <v-text-field
              :value="
                dataSiasn.gelarBelakang === '' ? '-' : dataSiasn.gelarBelakang
              "
              label="Gelar Belakang"
              outlined
              readonly
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="px-4" v-if="showSiasnData === true">
      <v-col cols="12" md="3" sm="6">
        <v-text-field
          :value="dataSiasn.nik === '' ? '-' : dataSiasn.nik"
          label="NIK*"
          :rules="rulesNotEmtpy"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <v-text-field
          :value="dataSiasn.tempatLahir === '' ? '-' : dataSiasn.tempatLahir"
          label="Tempat Lahir"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <v-text-field
          :value="dataSiasn.tglLahir === '' ? '-' : dataSiasn.tglLahir"
          label="Tanggal Lahir"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <v-text-field
          :value="dataSiasn.agama === '' ? '-' : dataSiasn.agama"
          label="Agama"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="px-4" v-if="showSiasnData === true">
      <v-col cols="12" md="6" sm="6">
        <v-text-field
          :value="dataSiasn.alamat === '' ? '-' : dataSiasn.alamat"
          label="Alamat"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <v-text-field
          :value="
            dataSiasn.pendidikanTerakhirNama === ''
              ? '-'
              : dataSiasn.pendidikanTerakhirNama
          "
          label="Pendidikan Terakhir"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <v-text-field
          :value="dataSiasn.tahunLulus === '' ? '-' : dataSiasn.tahunLulus"
          label="Tahun Lulus"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row class="px-4 mt-8" v-if="showSiasnData === true">
      <v-col cols="12" md="12" sm="12">
        <span class="indigo--text text-h6 font-weight-bold"
          >DATA KEPEGAWAIAN</span
        >
      </v-col>
    </v-row>
    <v-row class="px-4" v-if="showSiasnData === true">
      <v-col cols="12" md="3" sm="6">
        <v-text-field
          :value="dataSiasn.nipBaru === '' ? '-' : dataSiasn.nipBaru"
          label="NIP Baru"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <v-text-field
          :value="dataSiasn.nipLama === '' ? '-' : dataSiasn.nipLama"
          label="NIP Lama"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-text-field
          :value="dataSiasn.noSeriKarpeg === '' ? '-' : dataSiasn.noSeriKarpeg"
          label="No Seri Kartu Pegawai"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="px-4" v-if="showSiasnData === true">
      <v-col cols="12" md="6" sm="6">
        <v-text-field
          :value="dataSiasn.jenisJabatan === '' ? '-' : dataSiasn.jenisJabatan"
          label="Jenis Jabatan"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-text-field
          :value="dataSiasn.jabatanNama === '' ? '-' : dataSiasn.jabatanNama"
          label="Jabatan"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="px-4" v-if="showSiasnData === true">
      <v-col cols="12" md="3" sm="6">
        <v-text-field
          :value="dataSiasn.golRuangAwal === '' ? '-' : dataSiasn.golRuangAwal"
          label="Gol Ruang Awal"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <v-text-field
          :value="
            dataSiasn.golRuangAkhir === '' ? '-' : dataSiasn.golRuangAkhir
          "
          label="Gol Ruang Akhir"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <v-text-field
          :value="dataSiasn.pangkatAkhir === '' ? '-' : dataSiasn.pangkatAkhir"
          label="Pangkat Akhir"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <v-text-field
          :value="dataSiasn.masaKerja === '' ? '-' : dataSiasn.masaKerja"
          label="Masa Kerja"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="px-4" v-if="showSiasnData === true">
      <v-col cols="12" md="12" sm="6">
        <v-text-field
          :value="dataSiasn.unorNama === '' ? '-' : dataSiasn.unorNama"
          label="Nama Bidang"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="px-4" v-if="showSiasnData === true">
      <v-col cols="12" md="12" sm="6">
        <v-text-field
          :value="
            dataSiasn.unorIndukNama === '' ? '-' : dataSiasn.unorIndukNama
          "
          label="Nama Induk Bidang"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="px-4" v-if="showSiasnData === true">
      <v-col cols="12" md="6" sm="6">
        <v-text-field
          :value="
            dataSiasn.satuanKerjaKerjaNama === ''
              ? '-'
              : dataSiasn.satuanKerjaKerjaNama
          "
          label="Satuan Kerja"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-text-field
          :value="
            dataSiasn.satuanKerjaIndukNama === ''
              ? '-'
              : dataSiasn.satuanKerjaIndukNama
          "
          label="Satuan Kerja Induk"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="px-4" v-if="showSiasnData === true">
      <v-col cols="12" md="6" sm="6">
        <v-text-field
          :value="dataSiasn.kanregNama === '' ? '-' : dataSiasn.kanregNama"
          label="Kantor Regional"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4" sm="6">
        <v-text-field
          :value="dataSiasn.lokasiKerja === '' ? '-' : dataSiasn.lokasiKerja"
          label="Lokasi Kerja"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="px-4" v-if="showSiasnData === true">
      <v-col cols="12" md="6" sm="6">
        <v-text-field
          :value="
            dataSiasn.instansiKerjaNama === ''
              ? '-'
              : dataSiasn.instansiKerjaNama
          "
          label="Instansi Kerja"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <v-text-field
          :value="
            dataSiasn.instansiIndukNama === ''
              ? '-'
              : dataSiasn.instansiIndukNama
          "
          label="Instansi Induk"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row class="px-4 mt-8" v-if="showSiasnData === true">
      <v-col cols="12" md="12" sm="12">
        <span class="orange--text text-h6 font-weight-bold"
          >INFORMASI KONTAK</span
        >
      </v-col>
    </v-row>
    <v-row class="px-4" v-if="showSiasnData === true">
      <v-col cols="12" md="6" sm="6">
        <v-text-field
          :value="dataSiasn.email === '' ? '-' : dataSiasn.email"
          label="Email"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <v-text-field
          :value="dataSiasn.noHp === '' ? '-' : dataSiasn.noHp"
          label="No Hp"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="3" sm="6">
        <v-text-field
          :value="dataSiasn.noTelp === '' ? '-' : dataSiasn.noTelp"
          label="No Telpon"
          outlined
          readonly
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <div v-if="false">
      <v-row v-if="false">
        <v-col cols="12" md="6" class="py-0 my-0">
          <div class="d-flex flex-column px-2">
            <span class="mb-2">Tempat Lahir</span>
            <v-text-field
              v-model="itemPns.tempatLahir"
              outlined
              dense
              :rules="rulesNotEmtpy"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="py-0 my-0">
          <div class="d-flex flex-column px-2">
            <span class="mb-2">Tahun Gaji</span>
            <v-autocomplete
              v-model="itemPns.tahunGaji"
              outlined
              :items="itemsTahun"
              :rules="rulesNotEmtpy"
              item-value="description"
              item-text="description"
              auto-select-first
              dense
              chips
              deletable-chips
              color="blue-grey lighten-2"
              persistent-hint
            ></v-autocomplete>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col cols="12" md="6" class="py-0 my-0">
          <v-menu
            v-model="menuDate1"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex flex-column px-2">
                <span class="mb-2">Tanggal Lahir</span>
                <v-text-field
                  outlined
                  dense
                  :value="computedDateFormattedDatefns(itemPns.tanggalLahir)"
                  prepend-inner-icon="mdi-calendar"
                  clearable
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="itemPns.tanggalLahir = null"
                ></v-text-field>
              </div>
            </template>

            <v-date-picker
              v-model="itemPns.tanggalLahir"
              @change="menuDate1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6" class="py-0 my-0">
          <v-row>
            <v-col cols="5">
              <div class="d-flex flex-column px-2">
                <span class="mb-2">Golongan Lama</span>
                <v-text-field
                  v-model="itemPns.golonganLama"
                  outlined
                  dense
                  :rules="rulesNotEmtpy"
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="7">
              <v-menu
                v-model="menuDate1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex flex-column px-2">
                    <span class="mb-2">TMT Golongan Lama</span>
                    <v-text-field
                      outlined
                      dense
                      :value="
                        computedDateFormattedDatefns(itemPns.tmtGolonganLama)
                      "
                      prepend-inner-icon="mdi-calendar"
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="itemPns.tmtGolonganLama = null"
                    ></v-text-field>
                  </div>
                </template>

                <v-date-picker
                  v-model="itemPns.tmtGolonganLama"
                  @change="menuDate1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col cols="12" md="6" class="py-0 my-0">
          <v-row>
            <v-col cols="9">
              <div class="d-flex flex-column px-2">
                <span class="mb-2">Pendidikan Lama</span>
                <v-text-field
                  v-model="itemPns.riwayatPendidikan"
                  outlined
                  dense
                  :rules="rulesNotEmtpy"
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="3">
              <div class="d-flex flex-column px-2">
                <span class="mb-2">Tahun lulus</span>
                <v-text-field
                  v-model="itemPns.riwayatTahunPendidikan"
                  outlined
                  dense
                  :rules="rulesNotEmtpy"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6" class="py-0 my-0">
          <div class="d-flex flex-column px-2">
            <span class="mb-2">Satuan Kerja</span>
            <v-text-field
              v-model="itemPns.satuanKerja"
              outlined
              dense
              :rules="rulesNotEmtpy"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col cols="12" md="6" class="py-0 my-0">
          <div class="d-flex flex-column px-2">
            <span class="mb-2">Gelar Depan</span>
            <v-text-field
              v-model="itemPns.gelarDepan"
              outlined
              dense
              :rules="rulesNotEmtpy"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="py-0 my-0">
          <div class="d-flex flex-column px-2">
            <span class="mb-2">Gelar Belakang</span>
            <v-text-field
              v-model="itemPns.gelarBelakang"
              outlined
              dense
              :rules="rulesNotEmtpy"
            ></v-text-field>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col cols="12" md="6" class="py-0 my-0">
          <div class="d-flex flex-column px-2">
            <span class="mb-2">Unit Kerja</span>
            <v-text-field
              v-model="itemPns.unitKerja"
              outlined
              dense
              :rules="rulesNotEmtpy"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="py-0 my-0">
          <v-row>
            <v-col cols="6">
              <div class="d-flex flex-column px-2">
                <span class="mb-2">Masa kerja lama (tahun/bulan)</span>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="itemPns.tahunMasaKerjaLama"
                      outlined
                      dense
                      :rules="rulesNotEmtpy"
                      persistent-hint
                      hint="Tahun"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="itemPns.bulanMasaKerjaLama"
                      outlined
                      dense
                      :rules="rulesNotEmtpy"
                      persistent-hint
                      hint="Bulan"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="d-flex flex-column px-2">
                <span class="mb-2">Gaji Pokok Lama</span>
                <v-text-field
                  v-model="itemPns.gajiPokokLama"
                  outlined
                  dense
                  :rules="rulesNotEmtpy"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col cols="12" md="6" class="py-0 my-0">
          <div class="d-flex flex-column px-2">
            <span class="mb-2">Unit Kerja Induk</span>
            <v-text-field
              v-model="itemPns.unitKerjaInduk"
              outlined
              dense
              :rules="rulesNotEmtpy"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="py-0 my-0">
          <v-row>
            <v-col cols="6">
              <div class="d-flex flex-column px-2">
                <span class="mb-2">Masa kerja (tahun/bulan)</span>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="itemPns.tahunMasaKerjaBaru"
                      outlined
                      dense
                      :rules="rulesNotEmtpy"
                      persistent-hint
                      hint="Tahun"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="itemPns.bulanMasaKerjaBaru"
                      outlined
                      dense
                      :rules="rulesNotEmtpy"
                      persistent-hint
                      hint="Bulan"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="d-flex flex-column px-2">
                <span class="mb-2">Gaji Pokok Baru</span>
                <v-text-field
                  v-model="itemPns.gajiPokokBaru"
                  outlined
                  dense
                  :rules="rulesNotEmtpy"
                ></v-text-field>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col cols="12" md="6" class="py-0 my-0">
          <div class="d-flex flex-column px-2">
            <span class="mb-2">Jabatan Fungsional Umum Lama</span>
            <v-text-field
              v-model="itemPns.jabatanFungsionalUmumLama"
              outlined
              dense
              :rules="rulesNotEmtpy"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="py-0 my-0">
          <v-menu
            v-model="menuDate1"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex flex-column px-2">
                <span class="mb-2">TMT Jabatan Fungsional Umum Lama</span>
                <v-text-field
                  outlined
                  dense
                  :value="
                    computedDateFormattedDatefns(
                      itemPns.tmtJabatanFungsionalUmumLama
                    )
                  "
                  prepend-inner-icon="mdi-calendar"
                  clearable
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="itemPns.tmtJabatanFungsionalUmumLama = null"
                ></v-text-field>
              </div>
            </template>

            <v-date-picker
              v-model="itemPns.tmtJabatanFungsionalUmumLama"
              @change="menuDate1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col cols="12" md="6" class="py-0 my-0">
          <div class="d-flex flex-column px-2">
            <span class="mb-2">Jabatan Fungsional Umum Baru</span>
            <v-text-field
              v-model="itemPns.jabatanFungsionalUmumBaru"
              outlined
              dense
              :rules="rulesNotEmtpy"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="py-0 my-0">
          <v-menu
            v-model="menuDate1"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex flex-column px-2">
                <span class="mb-2">TMT Jabatan Fungsional Umum Baru</span>
                <v-text-field
                  outlined
                  dense
                  :value="
                    computedDateFormattedDatefns(
                      itemPns.tmtJabatanFungsionalUmumBaru
                    )
                  "
                  prepend-inner-icon="mdi-calendar"
                  clearable
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="itemPns.tmtJabatanFungsionalUmumBaru = null"
                ></v-text-field>
              </div>
            </template>

            <v-date-picker
              v-model="itemPns.tmtJabatanFungsionalUmumBaru"
              @change="menuDate1 = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row v-if="false">
        <v-col cols="12" md="6" class="py-0 my-0">
          <div class="d-flex flex-column px-2">
            <span class="mb-2">Lokasi Kerja</span>
            <v-text-field
              v-model="itemPns.lokasiKerja"
              outlined
              dense
              :rules="rulesNotEmtpy"
            ></v-text-field>
          </div>
        </v-col>
        <v-col cols="12" md="6" class="py-0 my-0">
          <v-row>
            <v-col cols="5">
              <div class="d-flex flex-column px-2">
                <span class="mb-2">Golongan Baru</span>
                <v-text-field
                  v-model="itemPns.golonganBaru"
                  outlined
                  dense
                  :rules="rulesNotEmtpy"
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="7">
              <v-menu
                v-model="menuDate1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex flex-column px-2">
                    <span class="mb-2">TMT Golongan Baru</span>
                    <v-text-field
                      outlined
                      dense
                      :value="
                        computedDateFormattedDatefns(itemPns.tmtGolonganBaru)
                      "
                      prepend-inner-icon="mdi-calendar"
                      clearable
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="itemPns.tmtGolonganBaru = null"
                    ></v-text-field>
                  </div>
                </template>

                <v-date-picker
                  v-model="itemPns.tmtGolonganBaru"
                  @change="menuDate1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <UploadImageDialog
      ref="refUploadDialog"
      @eventUploadSuccess="completeUploadSuccess"
    >
    </UploadImageDialog>
  </div>
</template>

<script>
import { format, parseISO } from "date-fns";
import FileService from "@/services/apiservices/file-service";
import UploadImageDialog from "@/components/utils/UploadImageDialog";
import ApiSiasnService from "@/services/apiservices/api-siasn-service";
export default {
  name: "FtRegistrasiLayananDesc2",
  components: {
    UploadImageDialog,
  },
  props: {
    // itemModified: Object,
  },
  data() {
    return {
      snackBarMesage: "",
      snackbar: false,
      // dataSiasn: undefined,
      transparent: "rgba(255, 255, 255, 0)",

      showSiasnData: true,
      siasnLookupEnable: false,

      itemPns: {
        id: 1,
        tempatLahir: "Mojokerto",
        tahunGaji: 2021,
        tanggalLahir: "1980-04-05",
        golonganLama: "III/D",
        golonganBaru: "III/A",
        tmtGolonganLama: "2020-08-07",
        tmtGolonganBaru: "2022-09-07",
        riwayatPendidikan: "S-2 Sistem Informasi",
        riwayatTahunPendidikan: 2010,
        satuanKerja: "Pemerintah Kota Palangka Raya",
        gelarDepan: "",
        gelarBelakang: "S.Kom., M.Kom.",
        unitKerja: "Bidang Mutasi Data dan Sistem Informasi Kepegawaian",
        unitKerjaInduk:
          "Badan Kepegawaian dan Pengembangan Sumber Daya Manusia",
        tahunMasaKerjaLama: 12,
        bulanMasaKerjaLama: 5,
        gajiPokokLama: 60000000,
        tahunMasaKerjaBaru: 9,
        bulanMasaKerjaBaru: 6,
        gajiPokokBaru: 100000000,
        jabatanFungsionalUmumLama: "Analisis Tata Usaha",
        tmtJabatanFungsionalUmumLama: "2020-09-10",
        jabatanFungsionalUmumBaru: "Penyusun Rencan Mutasi",
        tmtJabatanFungsionalUmumBaru: "2021-10-10",
        lokasiKerja: "Palangka Raya",
        statusPenyederhanaanBirokrasi: true,
      },
      date: format(parseISO(new Date().toISOString()), "dd-MMM-yyyy"),
      menuDate1: false,
      itemsTahun: [
        { id: 1, description: 2018 },
        { id: 2, description: 2019 },
        { id: 3, description: 2020 },
        { id: 4, description: 2021 },
        { id: 5, description: 2022 },
        { id: 6, description: 2023 },
        { id: 7, description: 2024 },
      ],
      rulesLenght: [
        (v) => !!v || " Tidak Boleh Kosong",
        (v) => v.length <= 255 || "Maksimal 255 Karakter",
        (v) => v.length > 1 || "Harus lebih besar dari 1 karakter",
      ],
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],
    };
  },
  computed: {
    itemModified: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemModified;
      },
      set(val) {
        this.$store.dispatch("FtRegistrasiModule/updateItemModified", val);
      },
    },
    dataSiasn: {
      get() {
        return this.$store.state.FtRegistrasiModule.dataSiasn;
      },
      set(val) {
        this.$store.dispatch("FtRegistrasiModule/updateDataSiasn", val);
      },
    },
  },
  watch: {
    itemModified: {
      handler(val) {
        if (val.nip !== "") {
          this.cariPegawaiWithNip(val.nip);
        }
      },
      deep: true,
    },

  },
  methods: {
    cariPegawaiWithNip(nip) {
      // const nipPegawai = this.itemModified.nip
      // const nipPegawai = "198502062010011008";
      try {
        if (nip !== undefined && nip.length === 18) {
          ApiSiasnService.getSiasnDataUtama(nip).then(
            (response) => {

              // console.log(JSON.stringify(this.itemModified));

              this.dataSiasn = response.data;
              this.itemModified.description = this.dataSiasn.nama;
              this.showSiasnData = true;
            },
            (err) => {
              console.log(err);
              // this.showSiasnData = false;
            }
          );
        } else {
          this.dataSiasn = "";
          this.itemModified.description = "";
          this.showSiasnData = false;
        }
      } catch (e) {
        e.toString();
      }
    },
    showDialogUpload() {
      if (this.itemModified.fdivisionBean !== undefined) {
        if (this.itemModified.id === 0) {
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly();
        }
        this.$refs.refUploadDialog.showDialog();
      } else {
        this.snackBarMesage = "Pcode, Pname dan Divisi harus diisi dahulu";
        this.snackbar = true;
      }
    },
    completeUploadSuccess: function (val) {
      // console.log(this.itemModified.avatarImage)
      if (
        this.itemModified.avatarImage !== undefined &&
        this.itemModified.avatarImage !== ""
      ) {
        FileService.deleteImage(this.itemModified.avatarImage).then(
          () => {
            // console.log(response.data);
          },
          (error) => {
            console.log(error.response);
          }
        );
      }

      if (val.fileName !== "") {
        this.$refs.refUploadDialog.closeDialog();
        this.itemModified.avatarImage = val.fileName;
        this.saveUpdateOnly();
      }
    },
    saveUpdateOnly() {
      this.$emit("saveUpdateOnly");
    },
    saveCreateOnly() {
      this.$emit("saveCreateOnly");
    },
    lookupImageUrl(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_medium(item.avatarImage);
      }
    },
    lookupImageUrlLazy(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },

    computedDateFormattedDatefns(value) {
      return value ? format(parseISO(value), "dd MMM yyyy") : "";
    },
  },
};
</script>

<style scoped>
.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>