<template>
  <div class="mt-4">
    <v-card>
      <v-card-text class="d-flex">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="fetchFtRegistrasiFile(itemModified.id)"
          >Reload Data</v-btn
        >
      </v-card-text>
      <v-card-text>
        <v-container class="pa-4">
          <v-data-table
            :headers="headers"
            :items="itemsFtRegistrasiFile"
            :page.sync="currentPage"
            :items-per-page="pageSize"
            hide-default-footer
            class="elevation-0"
            @page-count="totalTablePages = totalPaginationPages"
          >
            <template v-slot:[`item.description`]="{ item }">
              <span>
                {{ item.description }}
              </span>
              <span v-if="item.mandatory">
                <v-icon color="red" x-small>mdi-star-circle</v-icon>
              </span>
            </template>

            <template v-slot:[`item.fileName`]="{ item }">
              <div class="caption" v-if="item.fileName !== ''">
                <a :href="`${downloadFile(item)}`" target="_blank">
                  <v-icon color="green" small>mdi-download</v-icon>
                  {{ item.fileName }}
                </a>
              </div>
            </template>
            <template v-slot:[`item.modifiedBy`]="{ item }">
              <div class="subtitle-2">
                {{ item.modifiedBy }}
              </div>
              <div class="caption">
                {{ formatCreatedDate(item.created) }}
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-btn small @click="showDialogUpload(item)">
                <v-icon color="warning"> mdi-upload </v-icon>
              </v-btn>

              <v-btn v-if="false" @click="deleteDialogShow(item)" icon>
                <v-icon small color="red accent-4"> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="false">
        <v-container class="text-center">
          <v-btn color="primary" block @click="showDialogUpload"
            ><v-icon color="red">mdi-upload</v-icon> Upload
          </v-btn>
        </v-container>
      </v-card-actions>
    </v-card>

    <UploadDocumentSimpleDialog
      ref="refUploadDialog"
      @eventUploadSuccess="completeUploadSuccess"
    >
    </UploadDocumentSimpleDialog>

    <delete-confirm-dialog
      ref="refDeleteConfirmDialog"
      @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
      @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></delete-confirm-dialog>
  </div>
</template>

<script>
import FileService from "@/services/apiservices/file-service";
import FtRegistrasiFileService from "@/services/apiservices/ft-registrasi-file-service";

import FtRegistrasiFile from "@/models/ft-registrasi-file";
import DeleteConfirmDialog from "@/components/utils/DeleteConfirmDialog";

import { format, parseISO } from "date-fns";
import UploadDocumentSimpleDialog from "@/components/utils/UploadDocumentSimpleDialog.vue";

export default {
  name: "FtRegistrasiLayananDoc",
  components: {
    UploadDocumentSimpleDialog,
    DeleteConfirmDialog,
  },
  props: {},
  data() {
    return {
      snackbar: false,
      snackBarMesage: "",

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 50,
      pageSizes: [10, 25, 50, 150, 500],

      search: "",
      headers: [
        {
          text: "No",
          align: "start",
          sortable: true,
          value: "nomorUrut",
          width: "8%",
        },
        {
          text: "Deskripsi",
          align: "start",
          sortable: true,
          value: "description",
          width: "40%",
        },
        { text: "Nama File (Maks 2MB)", value: "fileName", width: "40%" },
        { text: "Actions", value: "actions", width: "10%", sortable: false },
      ],

      formMode: "",
      itemSelectedIndex: -1,
      itemSelected: "",
      currentFtRegistrasiFileBean: undefined,

      // itemsFtRegistrasiFile: [],

    };
  },
  computed: {
    itemModified: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemModified;
      },
      set(val) {
        this.$store.dispatch("FtRegistrasiModule/updateItemModified", val);
      },
    },

    itemsFtRegistrasiFile: {
      get() {
        return this.$store.state.FtRegistrasiModule.itemsFtRegistrasiFile;
      },
      set(value) {
        this.$store.dispatch(
            "FtRegistrasiModule/updateItemsFtRegistrasiFile",
            value
        )
      }
    },

  },
  watch: {
  },

  methods: {
    showDialogUpload(ftRegistrasiFileBean) {
      this.currentFtRegistrasiFileBean = ftRegistrasiFileBean;
      this.$refs.refUploadDialog.showDialog(ftRegistrasiFileBean);
    },
    completeUploadSuccess: function (val) {
      if (val.fileName !== "") {
        //Hapus dulu file lama
        if (this.currentFtRegistrasiFileBean.fileName !== '') {
          FileService.deleteFile(this.currentFtRegistrasiFileBean.fileName).then(
            () => {
              // console.log(response.data);
            },
            (error) => {
              console.log(error.response);
            }
          );
        }

        let dataFileBean = new FtRegistrasiFile(
          this.currentFtRegistrasiFileBean.id,
          val.fileName,
          val.description,
          "DOC",
          this.itemModified.id,
          this.currentFtRegistrasiFileBean.ftemplateRegDokumenBean,
          this.currentFtRegistrasiFileBean.fjenisDokumenBean
        );
        dataFileBean.modifiedBy = this.$store.state.auth.user.username;
        //Special Condition Upload: Karena Upload untuk case ini ftRegistrationFile adalah mengupdate FileNamanya saja
        FtRegistrasiFileService.updateFtRegistrasiFile(dataFileBean).then(
          (response) => {
            this.$emit("retrieveFiles", response.dummy);
            //Refresh data dan UI
            this.fetchFtRegistrasiFile(this.itemModified.id);

            this.$refs.refUploadDialog.closeDialog();

          },
          (error) => {
            console.log(error.message);
          }
        );
      }
      //Mereset lagi
      this.currentFtRegistrasiFileBean = "";
    },

    fetchFtRegistrasiFile(ftRegistrasiBean) {
      if (ftRegistrasiBean !== undefined) {
        FtRegistrasiFileService.getAllFtRegistrasiFileByFtRegistrasiBeanAndFileType(
          ftRegistrasiBean,
          "DOC"
        ).then(
          response => {
            this.itemsFtRegistrasiFile = response.data;
          },
          (error) => {
            console.log(error.data);
          }
        );
      }

    },

    downloadFile(item) {
      return FileService.file_url(item.fileName);
    },

    deleteDialogShow(item) {
      this.itemSelectedIndex = this.itemsFtRegistrasiFile.indexOf(item);
      this.itemSelected = Object.assign({}, item);
      this.$refs.refDeleteConfirmDialog.showDialog(
        this.itemSelectedIndex,
        item.description
      );
    },

    deleteItemConfirmedSingleSelect(index) {
      console.log("delete SingleItem: " + index);

      const deletedItem = this.itemsFtRegistrasiFile[this.itemSelectedIndex];
      FtRegistrasiFileService.deleteFtRegistrasiFile(deletedItem.id).then(
        () => {
          this.itemsFtRegistrasiFile.splice(this.itemSelectedIndex, 1);
          this.$refs.refUploadDialog.dialogShow = false;
        },
        (error) => {
          console.log(error);
        }
      );
      FileService.deleteFile(deletedItem.fileName).then(
        () => {
          // console.log(response.data);
        },
        (error) => {
          console.log(error.response);
        }
      );
    },
    deleteItemConfirmedMultiSelect(items) {
      console.log(items);
    },

    lookupImageUrl(item) {
      if (item.fileName === undefined) {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_low(item.fileName);
      }
    },
    lookupImageUrlLazy() {
      return "./assets/images/no_image_available.jpeg";
    },

    formatCreatedDate(dateCreated) {
      return format(
        parseISO(new Date(dateCreated).toISOString()),
        "dd-MMM-yyyy hh:mm:ss"
      );
    },
  },
  mounted() {},
};
</script>

<style scoped>
</style>