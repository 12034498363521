<template>
<div>
  <FtRegistrasiLayananEditStepper></FtRegistrasiLayananEditStepper>
</div>
</template>

<script>
import FtRegistrasiLayananEditStepper from "@/components/admin_registrasi_layanan_edit/FtRegistrasiLayananEditStepper.vue";

export default {
  components: {FtRegistrasiLayananEditStepper},
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>