<template>
  <div class="align-self-center">
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeDialog"
      transition="dialog-top-transition"
    >
      <v-card>
        <v-toolbar class="grey lighten-3" elevation="0" dense>
          <v-btn v-if="false" icon @click="closeDialog">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title class="blue--text text--darken-2">{{
            toolBarTitle
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog" color="red">
            <v-icon class="font-weight-bold">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-form v-model="valid" ref="form">
          <v-card-text class="justify-center">
            <v-row justify="center" align="center" v-if="false">
              <v-col cols="12" md="12" sm="6">
                <v-text-field
                  label="Deskripsi"
                  v-model="description"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="false">
              <v-col cols="12" sm="4" md="4">
                <v-menu
                  v-model="menuDate1"
                  :close-on-content-click="false"
                  max-width="290"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="computedDateFormattedDatefns(tanggalDoc)"
                      prepend-inner-icon="mdi-calendar"
                      clearable
                      label="Tanggal"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      @click:clear="tanggalDoc = null"
                      outlined
                      dense
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="tanggalDoc"
                    @change="menuDate1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="nomorDoc"
                  label="Nomor"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row justify="center" align="center">
              <v-col cols="8">
                <v-file-input
                  v-model="currentFile"
                  show-size
                  required
                  counter
                  label="Pilih Dokumen"
                  accept=".pdf"
                  :rules="fileSizeRule"
                  @change="selectImage"
                ></v-file-input>
                <!--              accept=".pdf,.zip,.rar,.doc,.docx, .xls, .xlsx, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"-->
              </v-col>

              <v-col cols="4" class="pl-2">
                <v-btn
                  color="success"
                  small
                  @click="startUpload"
                  :disabled="!currentFile || parentId === 0"
                >
                  Upload
                  <v-icon right dark>mdi-cloud-upload</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <div v-if="progress">
              <div>
                <v-progress-linear
                  v-model="progress"
                  color="light-blue"
                  height="25"
                  reactive
                >
                  <strong>{{ progress }} %</strong>
                </v-progress-linear>
              </div>
            </div>

            <v-row v-if="previewImage" justify="center">
              <v-img
                class="ma-2"
                :src="previewImage"
                alt=""
                max-width="300px"
                max-height="300px"
              ></v-img>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>

      <v-alert v-if="message" border="left" color="blue-grey" dark>
        {{ message }}
      </v-alert>

      <close-confirm-dialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></close-confirm-dialog>
    </v-dialog>

    <v-dialog v-model="dialogProgres" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please Wait...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UploadService from "@/services/apiservices/file-upload-service";
import CloseConfirmDialog from "@/components/utils/CloseConfirmDialog";
import { format, parseISO } from "date-fns";

export default {
  name: "UploadDocumentSimpleDialog",
  props: {
    parentId: Number,
  },
  components: {
    CloseConfirmDialog,
  },
  data() {
    return {
      toolBarTitle: "Upload Document",

      valid: false,
      dialogProgres: false,
      dialogShow: false,
      formDialogOptions: {
        title1: "",
        title2: "",
        message1: "",
        message2: "",
        errorMessage: "",
        color: "grey lighten-3",
        width: 800,
        zIndex: 200,
        noconfirm: false,
      },

      date: format(parseISO(new Date().toISOString()), "dd-MMM-yyyy"),
      menuDate1: false,

      // itemDefault: '',
      // itemModified: '',

      currentFile: undefined,
      previewImage: undefined,
      fileSizeRule: [
        (value) =>
          !value || value.size < 2000000 || "File Tidak Boleh Lebih Dari 2 MB!",
      ],
      progress: 0,
      message: "",
      description: "",
      nomorDoc: "",
      tanggalDoc: "",

      imageInfos: [],
      maxSize: 1024 * 1024 * 2, // 2 MB (ukuran dalam byte)
    };
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault);
      const modifiedItem = JSON.stringify(this.itemModified);
      return defaultItem !== modifiedItem;
    },
    // fileSizeRule() {
    //   return [
    //     (file) => {
    //       console.log(file);
    //       if (!file) return true; // Izinkan jika tidak ada file yang dipilih
    //       return (
    //         file.size <= this.maxSize ||
    //         "Ukuran file melebihi batas maksimal (2 MB)"
    //       );
    //     },
    //   ];
    // },
  },

  methods: {
    selectImage(image) {
      this.currentFile = image;
      // this.previewImage = URL.createObjectURL(this.currentFile);
      this.progress = 0;
      this.message = "";
    },

    startUpload() {
      if (this.$refs.form.validate()) {
        if (!this.currentFile) {
          this.message = "Please select an Image!";
          return;
        }

        this.progress = 0;
        this.dialogProgres = true;

        UploadService.uploadDocument(this.currentFile, (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        })
          .then((response) => {
            this.message = response.data.message;
            /**
             * Mendapat response nama file
             */
            const messageToParent = {
              fileName: this.message,
              description: this.description,
              nomorDoc: this.nomorDoc,
              tanggalDoc: this.tanggalDoc,
            };
            this.$emit("eventUploadSuccess", messageToParent);

            this.dialogProgres = false;
          })
          .catch((err) => {
            this.progress = 0;
            this.message = "Could not upload the image! " + err;
            this.currentFile = undefined;

            this.dialogProgres = false;
          });
      }
    },

    showDialog(dataFile) {
      this.dialogShow = true;
      this.currentFile = undefined;
      this.previewImage = undefined;
      this.progress = 0;
      this.message = "";

      this.toolBarTitle = `${dataFile.nomorUrut}. ${dataFile.description}`;
    },
    closeDialog() {
      if (!this.isItemModified) {
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
      } else {
        this.$refs.refCloseConfirmDialog.showDialog(
          " Sudah terdapat modifikasi data",
          "Tetap tutup dan reset perubahan?"
        );
      }
    },
    passingEventFromCloseConfirm(value) {
      if (value === "OKE") this.dialogShow = false;
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    computedDateFormattedDatefns(value) {
      return value ? format(parseISO(value), "dd-MMM-yyyy") : "";
    },
  },
  mounted() {
    // UploadService.getFiles().then((response) => {
    //   this.imageInfos = response.data;
    // });
  },
};
</script>

<style scoped>
.preview {
  max-width: 200px;
}
</style>
